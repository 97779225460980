// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-feature-js": () => import("./../../../src/templates/feature.js" /* webpackChunkName: "component---src-templates-feature-js" */),
  "component---src-templates-features-js": () => import("./../../../src/templates/features.js" /* webpackChunkName: "component---src-templates-features-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-news-list-js": () => import("./../../../src/templates/news_list.js" /* webpackChunkName: "component---src-templates-news-list-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-poradnia-psych-ped-js": () => import("./../../../src/templates/poradniaPsychPed.js" /* webpackChunkName: "component---src-templates-poradnia-psych-ped-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-team-js": () => import("./../../../src/templates/team.js" /* webpackChunkName: "component---src-templates-team-js" */),
  "component---src-templates-worker-js": () => import("./../../../src/templates/worker.js" /* webpackChunkName: "component---src-templates-worker-js" */)
}

