module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dobra Przestrzeń - Psycholog Psychoterapeuta","short_name":"Dobra Przestrzeń","start_url":"/","background_color":"#ffffff","theme_color":"#fe8a00","display":"minimal-ui","icon":"static/images/favicon_logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e8107668c68cab90b2a1c7bdfc86a738"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics-gdpr/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-J4ZJCN8S91"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
